import React, { useLayoutEffect } from 'react';
import { ScrollView, StyleSheet, Text, Dimensions, Image, TouchableOpacity, Share } from 'react-native';
import { responsiveFontSize } from 'react-native-responsive-dimensions';
import { Icon, Button } from 'react-native-elements';
import HeroImage from './../../components/HeroImage';
import * as Linking from 'expo-linking';


export default function SingleMarketMap({ navigation, route }) {
  const { moduleId } = route.params;
  const module = global.modules[moduleId];
  const { latitude, longitude, address, title, description, imageTopUrl = undefined } = module;
  const apiKey = "AIzaSyC9TAeQLy_d94SLeTYQzW_lPFkDc8Tk8fw";
  const zoom = 19;
  const urlStaticMapImage = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=620x400&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${apiKey}`;
  const urlGoogleMapsToShare = `https://maps.google.com/?q=${latitude}%2C${longitude}`;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <Icon size={responsiveFontSize(2.5)} containerStyle={{ marginRight: 10 }} onPress={shareLocation} name='share' type='materialicons' color='white' />
    })
  }, [navigation]);

  const linkingGoogleMapsApp = () => {
    Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&dir_action=navigate`);
  };

  const shareLocation = async () => {
    const message = `${address} (${urlGoogleMapsToShare})`;
    await Share.share({ message }).catch( e => alert('Función no soportada en su navegador'));
  }

  return (
    <ScrollView style={styles.container}>
      { module.imageTopUrl != undefined && <HeroImage imageTopUrl={imageTopUrl} height={10} />}

      <Text style={styles.address}>{address}</Text>

      <TouchableOpacity onPress={() => navigation.navigate('fullScreenMap', { latitude, longitude, title, description, address })} >
        <Image style={styles.mapStyle} source={{ uri: urlStaticMapImage }} />
      </TouchableOpacity>

      <Button title='Llévame ahí'
        buttonStyle={{ backgroundColor: '#b39d5b', marginLeft: 5, marginRight: 5 }}
        titleStyle={{ color: 'white' }}
        onPress={linkingGoogleMapsApp}
      />

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  directionContainer: {
    //backgroundColor: "red",
    flex: 0.3,
    flexDirection: 'row',
    padding: 10
  },
  address: {
    fontSize: responsiveFontSize(1.8),
    padding: 8,
  },
  mapStyle: {
    height: Dimensions.get('window').height / 3,
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
  },
});