import * as firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD7jWllUtt3B4vDRUCuWaVlwnK0hZLh_YI",
    authDomain: "cullinans-joyeria.firebaseapp.com",
    databaseURL: "https://cullinans-joyeria.firebaseio.com",
    projectId: "cullinans-joyeria",
    storageBucket: "cullinans-joyeria.appspot.com",
    messagingSenderId: "523764966877",
    appId: "1:523764966877:web:c06c35c98f8b659f878234",
    measurementId: "G-21FS6CTQ7S"
  };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
