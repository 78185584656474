import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';

export default function WebEmbeded({ route }) {
	const { moduleId } = route.params;
	const module = global.modules[moduleId];

	return (
		<View style={styles.container} >
			<iframe id="inlineFrameExample"
				title="Inline Frame Example"
				height={Dimensions.get('window').height}
				width={Dimensions.get('window').width}
				frameBorder="0"
				src={module.url}>
			</iframe>
		</View>
	)

}

const styles = StyleSheet.create({
	container: {
		flex: 1
	}
})