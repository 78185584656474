import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, StatusBar } from 'react-native';
import { AppLoading } from 'expo';
import Navigation from './src/navigation/Navigation';
import * as Permissions from 'expo-permissions';
import messaging from './src/utils/messaging';


export default function App() {
  const [isReady, setIsReady] = useState(false);

  async function _initApp() {
    return new Promise(async (resolve, reject) => {
      const res = await fetch('https://us-central1-cullinans-joyeria.cloudfunctions.net/service/tree');
      const json = await res.json();
      global.modules = json.modules;
      resolve(true);
    });
  }

  async function getTokenForPushNotifications() {
    try {
      const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      let finalStatus = existingStatus;

      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
        finalStatus = status;
      }

      if (finalStatus !== 'granted') {
        return null;
      }

      const token = await messaging.getToken();

      return token;

    } catch (e) {
      console.log("ERROR", e);
      alert(e);
      return null;
    }
  }

  useEffect(() => {
    if (messaging != null) {
      getTokenForPushNotifications().then(async token => {

        const device = {
          token,
          devicePushToken: token,
          expoPushToken: token,
          fcmToken: token,
          type: 'pwa',
          platform: 'web',
        }

        // Registrar dispositivo en la bd
        const options = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(device)
        }

        console.log("FCM token> ", token);

        fetch('https://us-central1-cullinans-joyeria.cloudfunctions.net/service/register-device', options)
        .then(r => console.log(r))
        .catch((e) => console.log(e));

      });
    }

  }, []);

  if (!isReady) {
    return (
      <AppLoading
        startAsync={_initApp}
        onFinish={(e) => { setIsReady(true) }}
        onError={console.warn}
      />
    )
  }

  return (
    <View style={styles.container}>
      <StatusBar translucent backgroundColor="white" barStyle={Platform.OS === "ios" ? "light-content" : "dark-content"} />
      <Navigation />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
