import React, { useState, useEffect, useLayoutEffect } from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import db from './../../utils/firestore';
import MapView from 'react-native-web-maps';
import { Icon, Overlay, CheckBox, Button } from 'react-native-elements';
import { responsiveFontSize, responsiveHeight } from 'react-native-responsive-dimensions';


export default function ManyLocationsMap({ route, navigation }) {
	const { moduleId } = route.params;
	const [locations, setLocations] = useState([]);
	const [categories, setCategories] = useState([]);
	const [categoriesHash, setCategoriesHash] = useState({});
	const [overlayVisible, setOverlayVisible] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// obtener categories
		db.collection('modules').doc(moduleId).collection('categories')
			.get()
			.then(snapshot => {
				let finalCategories = [];
				let finalCategoriesHash = {};
				snapshot.forEach(c => {
					finalCategories.push({ id: c.id, name: c.get('name') });
					finalCategoriesHash[c.id] = { name: c.get('name'), color: c.get('color'), checked: true };
				});
				setCategories(finalCategories);
				setCategoriesHash(finalCategoriesHash);

				// obtener locations
				db.collection('modules').doc(moduleId).collection('locations').orderBy('title')
					.get()
					.then(snapshot => {
						let finalLocations = [];
						snapshot.forEach(l => {
							console.log(l)
							finalLocations.push({ id: l.id, title: l.get('title'), description: l.get('description'), address: l.get('address'), latitude: l.get('latitude'), longitude: l.get('longitude'), categoryId: l.get('categoryId') })
						})
						setLocations(finalLocations);
						setLoading(false);
					})
			})
	}, []);


	useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => <Icon size={responsiveFontSize(2.8)} onPress={() => setOverlayVisible(!overlayVisible)} containerStyle={{ marginRight: 10 }} name='filter' type='material-community' color='white' />
		});
	}, [navigation]);

	const updateCategoryChecked = (id) => {
		setCategoriesHash({
			...categoriesHash,
			[id]: {
				...categoriesHash[id],
				checked: !categoriesHash[id].checked
			}
		})
	}

	const OverlayToFilterLocations = () => (
		<Overlay isVisible={true} onBackdropPress={() => setOverlayVisible(false)} style={{ 
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			margin: 'auto',  
			borderStyle: 'none', 
			position: 'absolute', 
			zIndex:'1'
			 }} >
			<Text style={{ fontSize: 15, marginBottom: 10, fontWeight: 'bold' }}>Filtrar por categoria</Text>
			<>
			{
				categories.map((c, i) => (
						<CheckBox
							key={c.id}
							title={c.name}
							checked={categoriesHash[c.id].checked}
							onPress={() => updateCategoryChecked(c.id)}
						/>
					
				)
				)
			}
			</>
			<Button title="Cerrar" type="outline" buttonStyle={{ marginTop: 8 }} onPress={() => setOverlayVisible(false)} />
		</Overlay>
	)

	if (loading)
		return (
			<View style={{ flex: 1 }}>
				<Text>Cargando mapa...</Text>
			</View>
		)


	const navigateToLocationDetails = (i) => {
		let location = { ...locations[i] };
		location['category'] = categoriesHash[location.categoryId];
		navigation.navigate('locationDetails', { location, title: location.title });
	}

	const openListLocation = () => {
		navigation.navigate('listLocations', { locations, categoriesHash, title: route.params.title });
	}

	const Footer = () => {
		return (
			<View style={styles.tabBarContainer}>
				<Icon size={responsiveFontSize(2.8)} containerStyle={styles.externalLinkIcon} name='list' type='feather' color='white' onPress={openListLocation} />
			</View>
		)
	};

	return (
		<View style={styles.container} >
			{ overlayVisible && <OverlayToFilterLocations/>}
			
				<MapView style={styles.mapStyle}
					initialRegion={{
						latitude: 19.42508354784485,
						longitude: -99.00649845600128,
						latitudeDelta: 1.2632159826371776,
						longitudeDelta: 0.8696459233760692,
					}}
				>
					{
						locations.filter(location => {
							return categoriesHash[location.categoryId].checked
						})
							.map((l, i) => (
								<MapView.Marker
									key={l.id} pinColor={categoriesHash[l.categoryId].color} coordinate={{ latitude: l.latitude, longitude: l.longitude }} title={l.title} description={l.address}
									onPress={ () => navigateToLocationDetails(i) }>
								</MapView.Marker>
							))
					}
				</MapView>
			
			<Footer/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	mapStyle: {
		height: responsiveHeight(85.8),
		width: Dimensions.get('window').width,
	},
	tabBarContainer: {
		padding: 21,
		backgroundColor: '#a39e9d',
		height: '5%'
	},
	externalLinkIcon: {
		position: 'absolute',
		right: 15,
		top: 9
	}
})