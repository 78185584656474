import React from 'react';
import { Dimensions, Image  } from 'react-native';

export default function HeroImage({imageTopUrl, height}) {
    const dimensions = Dimensions.get('window');
    const imageHeight = Math.round(dimensions.width * 9 / 16) + height;

    return (
           <Image tint="light" resizeMode="cover"  style={{ height: imageHeight, width: '100%' }} source={{uri: imageTopUrl}} />  
    )
}