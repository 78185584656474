import React from 'react';
import { Platform, Dimensions, View } from 'react-native';

export default function CustomContent({ route }) {
	const { moduleId } = route.params;
	const module = global.modules[moduleId];
	let html = module.html;

	if (Platform.OS === "ios") {
		const initialScale = Dimensions.get('window').width > 768 ? 2.0 : 1.0;
		html = "<html> <head> <meta name='viewport' content='width=width,initial-scale=" + initialScale + ",maximum-scale=2.0,user-scalable=no'> </head> <body> " + html + "</body></html>";
	}

	return (
		<View style={{ flex: 1 }}>
			<iframe id="inlineFrameExample"
				title="Inline Frame Example"
				height={Dimensions.get('window').height}
				width={Dimensions.get('window').width}
				frameBorder="0"
				srcDoc={html}>
			</iframe>
		</View>
	)

}