import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import MapView from 'react-native-web-maps';

export default function FullScreenMap({ route }) {
	const { latitude, longitude, address, description } = route.params;

	return (
		<View style={styles.container} >
			<MapView style={styles.mapStyle}
				initialRegion={{
					latitude,
					longitude,
					latitudeDelta: 0.0922,
	          		longitudeDelta: 0.0421,
				}} 
			>
				<MapView.Marker coordinate={ {latitude, longitude} } title={address} description={description} />
			</MapView>
		</View>
	)

}

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	mapStyle: {
		height: Dimensions.get('window').height,
		width: Dimensions.get('window').width,
	}
})