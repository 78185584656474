import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, ActivityIndicator } from 'react-native';
//import StarRating from 'react-native-star-rating';
import { Rating, AirbnbRating } from 'react-native-elements';
import { Button, Card, Input } from 'react-native-elements';
import db from './../utils/firestore';


export default function Poll({route}) {
	const module = global.modules[route.params.moduleId];
	const { pollId } = module;

	const [ questions, setQuestions ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const [ starCount, setStarCount ] = useState(0)

	useEffect( () => {
		db.collection('polls').doc(pollId).collection('questions').orderBy('text')
		.get()
		.then( snapshot => {
			let finalQuestions = []
			snapshot.forEach( (q) => {
				finalQuestions.push( {id: q.id, text: q.get('text'), questionTypeId: q.get('questionTypeId'), answer: 0} );
			} )
			setQuestions(finalQuestions);
			setLoading(false);
		});
	}, []);

	if (loading) 
		return (
			<View style={ {flex: 1, justifyContent: 'center'} }> 
				<Text> Cargando </Text>
			</View>
		)

	const setAnswerToQuestion = (i, value, type) => {
		let questionsUpdated = questions.map( (q, index) => {
			if (i == index){
				if (type == "number")
					value = parseInt(value);
				q.answer = value;
			}
			return q
		})
		setQuestions(questionsUpdated);
	}

	const validForm = () => {
		for (let i = 0; i < questions.length; i++) {
			if (questions[i].answer == 0 || questions[i].answer == "") {
				return false;
			}
		}
		return true;
	}

	const sendAnswer = async () => {
		const formIsValid = validForm();

		if (!formIsValid) {
			alert("Debe de llenar todos los campos");
			return;
		}

		for (let i = 0; i < questions.length; i++) {
			const currentQuestion = questions[i];
			await db.collection('polls').doc(pollId).collection('questions').doc(currentQuestion.id)
			.collection('answers').set({value: currentQuestion.answer});
		}	

		alert('Respuestas enviadas!!');
	}

	return (
			<ScrollView >
				<Card>
				{
					questions.map( (q, i) => (
							<View style={ {flex: 1, alignItems: 'center'} } key={i}>
							<Text style={ {marginBottom: 12, marginTop: 1, fontSize: 17} } > { q.text } </Text>
							{ 
								q.questionTypeId == "vKozMkNmqjV1gzPFm6db" && 
								<AirbnbRating
								  defaultRating={0}
								  count={5}
								  size={20}
								  showRating={false}
								  onFinishRating={ (rating) => setAnswerToQuestion(i, rating, 'number') }
								  size={35}
								  starContainerStyle={ {marginBottom: 18} }
								/>
							}
							{
								q.questionTypeId == "RV0nWhJMGXHazjPqY9aF" &&
								<Input onChangeText={ value => setAnswerToQuestion(i, value, 'text') } />
							}
							</View>
						)
					)
				}
				<Button buttonStyle={ {backgroundColor: 'red', marginTop: 20} } title="Enviar" onPress={ () => sendAnswer() } />
				</Card>
	      	</ScrollView>
	)

}