import React, { useState } from 'react';
import { View, FlatList } from 'react-native';
import { ListItem, Avatar } from 'react-native-elements'
import HeroImage from './../../components/HeroImage';
import { Image } from "react-native-expo-image-cache";


export default function ListMenu({ moduleId, onPressMenuItem }) {
	const module = global.modules[moduleId];
	const keyExtractor = (item) => item.id;

	const renderItem = ({item}) => <ListItem key={item.id} 
							  title={item.title} 
							  containerStyle={{padding: 6}} 
							  onPress={ () => onPressMenuItem(item.id, item.moduleTypeId, item.phone, item.url, item.title, item.email, item.text)  }
							  bottomDivider 
							  leftElement={
								<Image
									tint='light'
									transitionDuration={1}
									style={ {height: 60, width: 60} }
									{...{preview:{uri: item.icon}, uri: item.iconUrl} }
								/>
							  }
						     />

	//expo start --no-dev --minify

    return (
        <View style={{flex: 1}}>
    		{ (module.imageTopUrl != undefined && module.imageTopUrl !=  "") && <HeroImage imageTopUrl={module.imageTopUrl} height={60}/> }
			<FlatList 
			    data={module.children} 
			    removeClippedSubviews={true}
			    initialNumToRender={6}
			    maxToRenderPerBatch={6}
			    keyExtractor={keyExtractor}
			    renderItem={renderItem}
			    windowSize={20}	
			/>				
        </View>
    )
}