import React, { useState, useEffect } from 'react';
import { Dimensions, StyleSheet, FlatList, View, Text, Image, TouchableOpacity } from 'react-native';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import db from './../../utils/firestore';

const WIDTH = Dimensions.get('window').width;

export default function PhotoGallery({ route }) {
    const { moduleId } = route.params;
    const numColumns = 4;

    const [images, setImages] = useState([]);
    const [currentCaption, setCurrentCaption] = useState('');
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);


    useEffect(() => {
        db.collection('modules').doc(moduleId).collection('images').orderBy('index')
            .get()
            .then(snapshot => {
                let finalImages = [];
                snapshot.forEach((image, index) => {
                    finalImages.push({ id: index, source: { uri: image.get('url') }, description: image.get('description') })
                });
                setLoading(false);
                setImages(finalImages);
            })

    }, [])


    const formatData = (dataList, numColumns) => {
        const totalRows = Math.floor(dataList.length / numColumns);
        let totalLastRow = dataList.length - (totalRows * numColumns);

        while (totalLastRow !== 0 && totalLastRow !== numColumns) {
            dataList.push({ id: totalLastRow, empty: true });
            totalLastRow++;
        }

        return dataList;
    }

    const renderItem = ({ item, index }) => {
        const { itemStyle, itemInvisible } = styles;

        if (item.empty) {
            return (
                <TouchableOpacity style={[itemStyle, itemInvisible]} />
            )
        }

        return (
            <TouchableOpacity style={itemStyle} onPress={() => { setPhotoIndex(index); setCurrentCaption(item.description); setIsOpen(true) }}>
                <Image source={{ uri: item.source.uri }} style={styles.image} resizeMode="cover" />
            </TouchableOpacity>
        )
    }

    return (
        <View styles={styles.container}>
            { loading && <Text>Cargando imágenes...</Text>}
            { (images.length == 0 && !loading) && <Text> No hay imágenes para mostrar </Text>}

            {isOpen && (
                <Lightbox
                    mainSrc={images.map(i => i.source.uri)[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length].source.uri}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length].source.uri}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => {
                        const newPhotoIndex = (photoIndex + images.length - 1) % images.length;
                        setPhotoIndex(newPhotoIndex)
                        setCurrentCaption(images[newPhotoIndex].description);
                    }}
                    onMoveNextRequest={() => {
                        const newPhotoIndex = (photoIndex + 1) % images.length;
                        setPhotoIndex(newPhotoIndex)
                        setCurrentCaption(images[newPhotoIndex].description);
                    }}
                    imageTitle={currentCaption}
                    imageCaption={currentCaption}
                />
            )}

            <FlatList
                data={formatData(images, numColumns)}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                numColumns={numColumns}
                initialNumToRender={6}
                maxToRenderPerBatch={6}
                removeClippedSubviews={true}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    image: {
        width: "100%",
        height: "100%",
        borderWidth: 1,
        borderColor: '#b39d5b',
        borderRadius: 2,
    },
    itemStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        flex: 1,
        margin: 1,
        height: WIDTH / 4
    },
    itemText: {
        color: 'white',
        fontSize: 30,
    },
    itemInvisible: {
        backgroundColor: 'transparent'
    }
})