import React, { useState, useEffect } from 'react';
import { SafeAreaView, FlatList, Text, Image } from 'react-native';
import { ListItem } from 'react-native-elements';
import * as Linking from 'expo-linking';


export default function YoutubeVideoList({route, navigatio}) {
	
	const [ videos, setVideos ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	const PLAYLIST_ID = "PLficuT7IspD6D4dnlP0HMZMdZ2tgqs2Li";
	const API_KEY = "AIzaSyCEDRKtFLcQYbdGc_LrvuwYz5z7UwQPWCM";
	const url = `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${PLAYLIST_ID}&part=snippet%2CcontentDetails&key=${API_KEY}`;

	const getVideos = async () => {
		const res = await fetch(url);
		const json = await res.json();
		setVideos(json['items']);
		console.log(videos);
	}

	useEffect( () => {
		getVideos();
		setLoading(false);
	}, [] );

	if (loading) return <Text> Cargando </Text>

	const keyExtractor = (_, index) => index.toString();
	const renderItem = ({item}) => <ListItem
										key={item.contentDetails.videoId} 
										title={item.snippet.title}
										bottomDivider
										chevron
										onPress={ () => Linking.openURL(`https://www.youtube.com/watch?v=${item.contentDetails.videoId}`) }
										leftElement={
											<Image source={{uri: item.snippet.thumbnails.medium.url}} style={{height: 100, width: 150}} resizeMode="contain"/>
										}
                					/>				

	return (
		<SafeAreaView style={{flex:1}}>
			<FlatList 
				data={videos}
				initialNumToRender={6}
				maxToRenderPerBach={6}
				keyExtractor={keyExtractor}
				renderItem={renderItem}
			/>
		</SafeAreaView>
	)
}