import React, { useLayoutEffect, useState } from 'react';
import { View, Share, StyleSheet, Dimensions, Text, Image, TouchableOpacity } from 'react-native';
import { Icon, Button, Divider } from 'react-native-elements';
import { days, months } from './../../utils/calendar';
import { responsiveHeight, responsiveWidth, responsiveFontSize, responsiveScreenHeight , responsiveScreenWidth , responsiveScreenFontSize } from 'react-native-responsive-dimensions';


const getFormatDate = (date) => {
	const dateInParts = date.split('-')
	const dateJs = new Date(parseInt(dateInParts[0]), parseInt(dateInParts[1]), parseInt(dateInParts[2]))
	const day = dateInParts[0];
	const dayString = days[ dateJs.getDay() ];
	const monthString = months[ parseInt(dateInParts[1]) - 1 ];
	const year = dateInParts[2];
	
	return `${dayString}, ${day} de ${monthString} de ${year}`;
}

export default function EventDetail({route, navigation}) {
	const { item: event } = route.params;
	const apiKey = "AIzaSyC9TAeQLy_d94SLeTYQzW_lPFkDc8Tk8fw";
	const zoom = 15;
	const hasCoordinates = (event.latitude && event.longitude) ? true : false;
	const urlStaticMapImage = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=620x400&maptype=roadmap&markers=color:red%7C${event.latitude},${event.longitude}&key=${apiKey}`;
	const fullDate = getFormatDate(event.date);
	const [ urlGoogleMapsToShare, setUrlGoogleMapsToShare ] = useState('');
	

	useLayoutEffect(()=>{
	    navigation.setOptions({
	      headerRight: () => <Icon size={responsiveFontSize(2.5)} containerStyle={ {marginRight: 10} } onPress={shareLocation} name='share' type='materialicons' color='white'/>
	    })
	}, [navigation]);

	const linkingGoogleMapsApp = () => {
    	Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${event.latitude},${event.longitude}&dir_action=navigate`);
  	};

  	const shareLocation = async () => {
		const address = (event.address != "") ? `- ${event.address}` : "";
		const hour = event.hour.slice(0,5);
		const urlGoogleMapsToShare = hasCoordinates ? `(https://maps.google.com/?q=${event.latitude}%2C${event.longitude})` : "";
	    const message = `${event.name} (${fullDate} ${hour}). ${event.details}. ${address} ${urlGoogleMapsToShare}`;

	    await Share.share({message});
	}


	return (
		<View style={styles.container}>
	      <Text style={styles.name}>{ event.name }</Text>
	      { 
	      	(event.details.length > 0) &&
	      	<View style={{padding: 5}}>
		      <Text style={styles.details}>{ event.details }</Text>
	      	</View>
	  	  }

	      <Divider style={styles.divider} />
	      	<Text style={styles.date}>{fullDate}</Text>
	      	<Text style={styles.hour}>{event.hour.slice(0, 5)}</Text>
	      <Divider style={styles.divider} />

	      { (event.address != "") && <Text style={styles.address}>{event.address}</Text> }

	      {		
	      	  (hasCoordinates) &&
	      	  <View>
			      <TouchableOpacity onPress={ () => navigation.navigate('fullScreenMap', { latitude: event.latitude, longitude: event.longitude, title: event.name, address: event.address}  ) } >
			        <Image style={styles.mapStyle} source={{uri: urlStaticMapImage}} />
			      </TouchableOpacity>
			      
			      <Button title='Llévame ahí' 
			        buttonStyle={{backgroundColor: '#b39d5b', marginLeft: 5, marginRight: 5 }}
			        titleStyle={{color: 'white'}}
			        onPress={linkingGoogleMapsApp}
			        />
		       </View>
	    	}

      	</View>
	)
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  name: {
  	padding: 5, 
  	fontSize: responsiveFontSize(2.6), 
  	fontWeight: 'bold', 
  	marginTop: 8
  },
  details: {
  	fontSize: responsiveFontSize(2),
  },
  divider: { 
  	backgroundColor: '#b39d5b', 
  	marginTop: 7, 
  	height: 2 
  },
  date: {
	paddingLeft: 8, 
	paddingTop: 8,
	fontSize: responsiveFontSize(1.9)
  },
  hour: {
  	paddingLeft: 8, 
  	paddingBottom: 8,
  	fontSize: responsiveFontSize(1.9)
  },
  address: {
    padding: 8,
    fontSize: responsiveFontSize(1.8)
  },
  mapStyle: {
    height: Dimensions.get('window').height / 3,
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 7,
  },
});