import React, { useState, useEffect } from 'react';
import { View, FlatList, Text } from 'react-native';
import { ListItem } from 'react-native-elements';
import db from './../../utils/firestore';


export default function EventList({route, navigation}) {
	const { moduleId } = route.params;

	const [ loading, setLoading ] = useState(true);
	const [ events, setEvents ] = useState([]);

	useEffect( () => {
		db.collection('modules').doc(moduleId).collection('events').orderBy('name')
		.get()
		.then( snapshot => {
			let finalEvents = [];
			snapshot.forEach( event => {
				finalEvents.push( {id: event.id, name: event.get('name'), details: event.get('details'), address: event.get('address'), latitude: event.get('latitude'), longitude: event.get('longitude'), date: event.get('date'), hour: event.get('hour')} )
			})
			setEvents(finalEvents);
			setLoading(false);
		})
	}, [])

	if (loading)
		return (
			<View>
				<Text> Cargando eventos... </Text>
			</View>
		)

	const keyExtractor = (_, index) => index.toString();
	const renderItem = ({item}) => { 
								   const dateInParts = item.date.split('-')
								   const day = parseInt(dateInParts[0]) < 10 ? `0${dateInParts[0]}` : dateInParts[0];
								   const month = parseInt(dateInParts[1]) < 10 ? `0${dateInParts[1]}` : dateInParts[1];
								   const year = dateInParts[2];
								   const date = `${day}/${month}/${year}`;
								   
								   return (
										<ListItem
											key={item.id} 
											title={item.name}
											subtitle={ date }
											subtitleStyle={{ color: 'orange', marginTop: 5, fontSize: 15 }}
											onPress={ () => navigation.navigate("eventDetail", { item } )}
											bottomDivider
											chevron
	                					/>		
                					)
                				}	

	return (
		<View>
			<FlatList 
				data={events}
				initialNumToRender={8}
				maxToRenderPerBach={8}
				keyExtractor={keyExtractor}
				renderItem={renderItem}
			/>
		</View>
	)
}